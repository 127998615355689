.card-table {
  width: 100%;
  text-align: left;
  font: 14px/1 $fira-regular;
  color: #000;
  background: #fff;
  box-shadow: $shadow;
  thead tr {
    th {
      &:nth-of-type(1) {
        text-align: center;
      }

    }
  }
  th {
    color: $c-main;
    border-bottom: 2px solid #eee;
    padding: 15px 0;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  td {
    vertical-align: middle;
    padding: 15px 0;
    border-bottom: 2px solid #eee;
  }
  tbody tr {
    td {
      &:nth-of-type(1) {
        text-align: center;
      }
      &:last-of-type {
        text-align: justify;
        padding: 0 5px;
        a {
          display: inline-block;
          position: relative;
          top: 8px;
        }
        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }
    }
    &:last-of-type {
      td {
        border: 0;
      }
    }
  }
  &_notice {
    td {
      font:14px $fira-regular;

    }
    tbody {
      tr {
        td {
          &:last-of-type {
            text-align: center;
          }
        }
      }

    }
  }
}

.sort-tri {
  display: inline-block;
  vertical-align: middle;
  //height: 13px;
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
  }

  &_default {
    &:before {
      border: 6px solid transparent;
      border-top: 9px solid #cecece;
    }
  }
  &_down {
    &:before {
      border: 6px solid transparent;
      border-bottom: 9px solid #388824;
    }
  }
  &_up {
    &:before {
      border: 6px solid transparent;
      border-top: 9px solid #ca3232;
    }
  }
}

.vacanc {
  &__action {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    color: $c-main;
    width: 24px;
    height: 24px;
    text-align: center;
    border: 2px solid $c-main;
    font-size: 0;
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      width: 0;
      vertical-align: middle;
    }
    svg {
      fill: $c-main;
      vertical-align: middle;
      @include transition(200);
    }
    &:hover {
      &.vacanc__action_watch {
        svg {
          fill: $c-second;
        }
      }
      &.vacanc__action_edit {
        svg {
          fill: #00b7e8;
        }
      }
      &.vacanc__action_delete {
        svg {
          fill: #c50600;
        }
      }
      &.vacanc__action_cancel {
        svg {
          fill: #ce8100;
        }
      }

    }
  }
  &__viners {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    color: $c-main;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border: 2px solid $c-main;
    margin-right: 10px;
  }
}

.add-vacanc-btn-row {
  text-align: right;
}

.vacanc-controls-row {
  text-align: right;
  margin: 10px 0 -20px;
}

.vacanc-popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    //transform: translate(-50%, -50%);
    max-width: 1180px;
    min-width: 920px;
    height: 250px;
    background-color: white;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, .2);
    border-radius: 3px;
    overflow: hidden;
    opacity: 1;
    padding: 20px;
    box-shadow: 10px 10px 80px black;
    opacity: 0;
    transform: translate(-50%, -50%) scale(.25);
    transition: all .5s .2s;
  }

  &__btn {
    width: 125px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }

  &__item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;

    &_up {
      width: 71%;
    }

    &_down {
      width: 31%;
    }
  }

  &__file-upload {
    width: 105px;
    border-radius: 4px;
    text-align: center;
    margin: 25px;
    cursor: pointer;
    padding: 7px 0 6px;
    transition: all 0.18s ease-in-out;
    border: 1px solid #4FD666;
    background: #39b549;
    color: #fff;
    font: 12px fira_sanslight,Arial,sans-serif;
    margin: 0;
  }

  &__file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.file-upload__details {
  font-size: 14px;
  display: block;
}

.decline-vacancy-btn {
  cursor: pointer;
  svg {
    transition: .3s;
    display: block;
    width: 14px;
    height: 14px;
    fill: #999;
  }
  &:hover {
    svg {
      fill: #333;
    }
  }
}

use.close-vac {
  cursor: pointer;
  fill: "#999";
}

.reject-request-message {
  color: red;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

