// breadcrumbs
.content {
  .crumbs {
    color: #929497;
    margin: 15px 0;
    font-weight: normal;
    font: 12px $fira-regular;

    a {
      color: $c-main;
      font: normal 12px $fira-regular;
    }
  }
}


.text {
  margin: 0;
  font-family: $fira-regular;

  &_common {
    padding: 0;
    color: #141414;
    border-bottom: none;
  }
}

// links
.link {
  color: $c-main;

  &_gray-border {
    transition: .3s;
    text-decoration: none;
    border-bottom: 1px solid #ccc;
    color: inherit !important;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      border-bottom: 1px solid #141414;
    }
  }
}

.mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);
  z-index: 100;
}

.popup {
  display: none;
  position: fixed;
  top: 230px;
  left: 50%;
  width: 630px;
  margin-left: -315px;
  z-index: 110;
}

.swal2-modal h2 {
  color: #575757;
  font-size: 20px !important;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 0;
  padding: 0;
  /*  line-height: 60px;*/
  display: block;
  line-height: 1 !important;
}

.swal2-modal button.styled {
  color: #fff;
  border: 0;
  box-shadow: none;
  font-size: 12px !important;
  font-weight: 500;
  border-radius: 3px;
  padding: 10px 32px;
  margin: 0 5px;
  cursor: pointer;
}

.charts__tabs > div > .greeenHistoryLine {
  background: #39b549;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  float: left;
  margin-left: 10px;
  margin-right: 5px;
}

.charts__tabs > div > .blueHistoryLine {
  background: #2B316E;
  //background: #0070bb;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  float: left;
  margin-left: 10px;
  margin-right: 5px;
}

.redHistoryLine {
  background: #b42533;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  float: left;
}

.charts__tabs > div > .historyTitle {
  font-size: 14px;
  margin-left: 15px;
  color: #2B316E;
  //color: #0070bb;
}

.bluehead {
  color: #2B316E;
  //color: #0070bb;
}

.charts__row > .openVacanc {
  padding-top: 10px;
  color: #2B316E;
  //color: #0070bb;
  font-size: 15px;
}

.flex {
  display: flex;
  align-items: center;
}

#position_vacancy_stavka {
  width: 43px !important;
  height: 30px;
  border: 1px solid #929497;
  background-color: #eff4f7;
  font-size: 15px;
}

.slide-forms__block-row.fs14 {
  span {
    margin-right: 4px;
  }
}

.slide-forms__text {
  color: #2B316E;
}

.historyTitle {
  font-size: 14px;
  margin-left: 15px;
  color: #2B316E;
  //color: #0070bb;
}

.line-block {
  padding-left: 740px;
  position: relative;
  top: -7px;
}

.modal-in {
  animation: .3s modal-in forwards;
}

@keyframes modal-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.my-2 {
  margin-left: 10px;
  margin-right: 10px;
}