.card {
  margin: 10px 0;
  &__inner-wrap {
    @include d-table;
  }
  &__col {
    display: table-cell;
    vertical-align: top;
    &_1 {
      width: 17%;
    }
    &_2 {
      padding-left: 1.5463%;
    }
  }
  &__logo {
    box-shadow: $shadow;
    background-image: url(/img/rastr/logo-placeholder.png);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 168px;
  }
  &__details {
    background: #fff;
    box-shadow: $shadow;
  }
  &__details-wrap {
    padding: 25px 20px 15px 15%;
  }
  &__details-text {
    margin: 20px 0;
  }
  &__btn-row {
    text-align: center;
    margin: 10px 0;
  }
  &__btn-upload {
    width: 130px;
  }
  &__btm-btn {
    text-align: center;
    margin: 10px 0;
  }
  &__edit {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.сard-with-photo {
  width: 79% !important;
}
