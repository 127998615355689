.login-submenu {
  width: 302px;
  background: #fff;
  position: absolute;
  top: 95px;
  left: 50%;
  margin-left: -151px;
  box-shadow: 4px 6px 19.74px 1.26px rgba(0, 0, 0, 0.27);
  z-index: 999;
  cursor: auto;
  line-height: 1;
  display: none;
  animation: 400ms slideOut forwards;
  a {
    line-height: 1;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 8px 1.26px rgba(0, 0, 0, 0.15);
    top: -8px;
    left: 50%;
    margin-left: -15px;
    background: #fff;
    transform: rotate(45deg);
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #eff4f7 inset;
  }
  &__overlay {
    position: absolute;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

  }

  &__container {
    position: relative;
    z-index: 1;
    padding: 20px 15px;
    color: $c-main-lighter;
    text-align: center;
    a {
      color: inherit;
    }
  }
  &__title {
    font: normal 12px/1 $fira-regular;
    margin-bottom: 30px;
  }
  &__link {
    font: 14px $fira-light;
    text-decoration: underline;
    display: block;
    margin: 30px 0 30px;
    &_register {
      margin: 5px 0;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__inp-text {
    margin: 5px 0;
  }
  &__btn-row {
    margin: 30px 0 30px;
    .btn {
      margin: 0 10px;
    }
  }
  &._visible {
    display: block;
  }
  &._animated {
    animation: 400ms slideIn forwards;
  }
}

.logged-submenu {
  position: absolute;
  //width: 100px;
  //margin-left: -50px;
  //left: 50%;
  width: 100%;
  top: 85px;
  box-shadow: $shadow;
  z-index: 999;
  cursor: auto;
  line-height: 1;
  display: none;
  animation: 400ms rollUp forwards;
  background: $c-main;
  color: #fff;
  transform-origin: top;
  a {
    color: inherit;
    text-decoration: none;
  }
  &__link {
    font: 10px/1.3em $fira-light;
    text-decoration: none;
    display: block;

    &:hover {
      background: #fff;
      .logged-submenu__link-text {
        color: $c-main;
      }
    }
  }
  &__link-text {
    display: block;
    padding: 8px 3px 6px;
  }
  &_user {
    background: $c-second;
    .logged-submenu__link {
      &:hover {
        .logged-submenu__link-text {
          color: $c-second;
        }
      }
    }
  }
  &._visible {
    display: block;
  }
  &._animated {
    animation: 400ms rollDown forwards;
  }
}



