.slide-forms {
  position: relative;

  .header3 {
    color: #2B316E;
    font-family: $fira-regular;
  }

  &__item {
  }

  .owl-nav {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    text-align: center;
    vertical-align: middle;
    top: calc(100% + 30px);

    .owl-prev,
    .owl-next {
      display: inline-block;
      position: relative;
      left: 140px;
      padding: 10px;
      vertical-align: middle;
      transform-origin: center;

      &.disabled {
        fill: $c-dark;
        cursor: auto;
      }
    }

    .owl-prev {
      left: -140px;
    }
  }

  &__btn-row {
    text-align: center;
    margin: 20px 0;

    .btn {
      margin-left: 15px;
      margin-right: 15px;
      position: relative;
      z-index: 2;
    }
  }

  &__requride-notice {
    font: 13px $fira-regular;
    color: $c-inactive;
    text-align: left;
    height: 15px;

    &_under-block {
      margin-left: 10px;
    }
  }

  &__wrapper {
    font-size: 0;
    margin: 0 -10px 20px;
  }

  &__grid {
    display: inline-block;
    font-size: 14px;
    vertical-align: top;

    &_20 {
      width: 20%;
    }

    &_33 {
      width: 33.3333333333333%;
    }

    &_50 {
      width: 50%;
    }

    &_66 {
      width: 66.66666666666666666666%;
    }

    &_80 {
      width: 80%;
    }

    &_100 {
      width: 100%;
    }
  }

  &__block {
    margin: 10px;
    background: #ffffff;
    box-shadow: $shadow;

    &_full-w {
      margin: 0;
    }

    &_mt0 {
      margin-top: 0;
    }

    &_mt20 {
      margin-top: 20px;
    }

    &_for-scroll {
      padding: 10px 5px 10px 10px;
    }

    &_edu {
      margin-left: 0;
      padding: 10px;
    }

    > .slide-forms__block-row {
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  &__block-scroll {
    height: 225px;
    overflow: auto;

    &_high {
      height: 379px;
      overflow: auto;
    }

    &_low {
      height: 90px;
      overflow: auto;
    }

    &_h340 {
      height: 340px;
      overflow: auto;
    }
  }

  &__block-row {
    border-bottom: 2px solid #eeeeee;
    padding: 10px 15px;

    &_top-gap {
      margin-top: 48px;
    }

    &_contacts {
      @include d-table;
      margin: 10px 0;

      &_no-gap {
        margin-top: 0;
        margin-bottom: -15px;
      }
    }

    .header3 {
      text-align: center;
      margin: 0;
    }

    &.select-mult1-wrap {
      padding: 0;

    }
  }

  &__add-check-row {
    margin: 10px 30px;
  }

  &__add-row {
    margin: 10px 30px;
  }

  &__age {
    color: $c-main;
    font: 14px $fira-light;
  }

  &__inp-age-label {
    font: 14px $fira-light;
    color: $c-main;

    + .slide-forms__inp-age-label {
      margin-left: 10px;
    }
  }

  &__inp-age {
    width: 43px !important;
    height: 30px !important;
    color: #000 !important;
    border-radius: 0 !important;
    border: 0 !important;
    background: #fff !important;
    box-shadow: $shadow;
    font: 14px $fira-light !important;
    display: inline-block;
    vertical-align: middle;

  }

  &__block-col {
    display: table-cell;
    vertical-align: middle;

    &_1 {
      width: 200px;
      text-align: right;
      padding-right: 10px;
    }

    &_3 {
      text-transform: uppercase;
      color: $c-main;
      padding-left: 10px;
      font: 12px $fira-light;
    }

    &_center {
      text-align: center;
    }

    &_text-right {
      text-align: right;
    }

    &_w150 {
      width: 150px;
    }

    &_w230 {
      width: 230px;
    }

    &_phone {
      vertical-align: top;
      padding-top: 5px;
    }

    &_checkbox {
      vertical-align: top;
      padding: 5px 0 0 20px;
    }

    &_popup-left {
      text-align: right;
      padding-right: 20px;
      font-size: 14px;
      font-family: $fira-light;
    }
  }

  &__cont-wrap {
    width: 70%;
    margin: 0 auto;
    padding: 40px 0;

    &_wide {
      width: 90%;
      margin: 0 auto;
      padding: 20px 0;
    }

    &_h370 {
      height: 370px;
    }

    &_h230 {
      height: 230px;
    }

    &_h120 {
      height: 120px;
    }
  }

  &__cont-label {
    color: $c-main;
    font: 12px $fira-light;
    text-transform: uppercase;
  }

  &__date-blocks {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin: 0 10px;

    &_left {
      margin-left: 0;

    }

    &_mr10 {
      margin-right: 10px !important;
    }
  }

  &__image-block {
    height: 200px;
    text-align: center;
    padding-top: 70px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    color: #2B316E;

    span {
      display: block;
      margin-bottom: 10px;
    }

    svg {
      fill: #2B316E;
    }
  }

  &__link {
    color: $c-main;
    transition: .3s;
    line-height: 16px;
    text-decoration: underline;

    &:hover {
      color: #23527c;
    }

    &_add-phone {
      position: relative;
      top: 2px;
      transition: .3s;
      text-align: right;
      cursor: pointer;
      font-size: 12px;
      line-height: 14px;
      color: #262e39;

      &:hover {
        color: $c-main;
      }
    }
  }

  &__edu-row {
    font: 12px $fira-light;
    color: #898989;

    &_text-right {
      text-align: right;
    }

    &_blue {
      color: $c-main;
      font-family: $fira-light;
    }
  }

  &__label {
    position: absolute;
    bottom: calc(100% - 5px);
    font-size: 12px;
    left: 0;
  }
}

input.slide-forms__add-to-select {
  width: 100%;
  border: 0;
  background: #fff;
  font: normal 14px $fira-light;
  padding: 5px;
}

.uploaded-list {
  &__item {
    position: relative;
    display: block;
    border-bottom: 2px solid #eee;
    padding-left: 70px;
    height: 42px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      left: 30px;
      width: 22px;
      height: 30px;
      background: url('/img/rastr/doc-icon.png');
    }

    &._remove-animation {
      animation: 500ms flipOutX forwards;
    }

    &_no-cut {
      position: relative;
      display: block;
      padding: 0 50px;
      min-height: 42px;

      &._remove-animation {
        animation: 500ms flipOutX forwards;
      }
    }
  }

  &__text {
    display: inline-block;
    font: 12px $fira-light;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 40px;
    overflow: hidden;
    max-width: 420px;
    color: $c-main;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &_no-cut {
      font: 12px $fira-medium;
      text-transform: uppercase;
      text-decoration: none;
      color: $c-main;
    }
  }

  &__autor {
    display: block;
    font: 12px $fira-light;
    color: $c-main;
  }

  &__remove-btn {
    position: absolute;
    cursor: pointer;
    top: 13px;
    right: 20px;

    &:hover {
      svg {
        fill: #333;
      }
    }

    svg {
      transition: .3s;
      display: block;
      width: 14px;
      height: 14px;
      fill: #999;
    }
  }
}

.publication-title-input {
  width: 697px;
}

.publication-author-input {
  width: 697px;
}

.pulication-add-bnt {

}

.slide-forms {
  .edit-account {
    .owl-nav {
      top: calc(100% + 45px);
    }
  }
}