.qtip-default {
  background: #fff;
  border: 0;
  color: #000;
  font: 9px $fira-regular;
  box-shadow: 0px 0px 9.3px 0.7px rgba(0, 0, 0, 0.25);
  padding: 2px 10px 0px;
  //position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom: 5px solid #fff;
    top: -9px;
    left: 50%;
    margin-left: -5px;
  }
}

.qtip-content {
  padding: 0;
}