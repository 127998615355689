.b-form {
  &__wrap {
    position: relative;
  }
  &__row {
    display: table;
    table-layout: fixed;
    width: 40%;
    margin-bottom: 10px;
  }
  &__cell {
    display: table-cell;
    vertical-align: middle;
    &_label {
      font: 12px $fira-light;
      text-transform: uppercase;
      color: $c-main;
      text-align: right;
      padding-right: 20px;
    }
  }

  &__bottom-right-box {
    position: absolute;
    text-align: right;
    right: 0;
    bottom: 0;
  }
  input[type="email"] {
    border-radius: 0;
  }
}

.b-form__captcha-widget {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  min-height: 55px;
}