.announce {
  font: 14px/1.3 $fira-light;
  color: #000;

  &__title {
    color: #fff;
    background: $c-main;
    padding: 4px 20px 2px;
    border-radius: 3px 3px 0 0;
  }
  &__content {
    background: #fff;
    border: 1px solid #d9dcdf;
    border-top: none;
    height: 500px;
    padding: 10px 5px 10px 20px;
    overflow: hidden;
  }
  &__item {
    border-bottom: 1px solid $c-dark;
    margin: 0 0 15px;
    + .item {
      margin-top: 15px;    }
  }
  &__link {
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    color: #000;
    display: block;
    &:hover {
      text-decoration: none;
      .announce__item-text {
        color: $c-dark;
      }
    }
  }
  &__item-title {
    margin: 0 0 5px;
    line-height: 1.3;
    font-size: 14px;
  }
  &__btns-row {
    text-align: right;
    margin-top: 5px;
  }
  &__btn {
    display: inline-block;
    width: 26px;
    height: 26px;
    text-decoration: none;
    margin-left: 5px;
    svg {
      fill: $c-dark;
    }

    &_blue {
      svg {
        fill: $c-main;
      }
    }
  }
}

