.tos {
  padding: 40px 60px;
  font-size: 15px;
  text-align: left;
  ul {
    padding-left: 30px;
    margin-top: 10px;
    >li {
      display: list-item;
      margin-bottom: 5px;
      list-style-type: disc;
    }
  }
  ol {
    padding-left: 30px;
    margin-top: 20px;
    >li {
      list-style-type: decimal;
      margin-bottom: 5px;
    }
  }
  h2 {
    color: #575757;
    font-size: 30px !important;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: 1 !important;
  }
}