.footer2 {
  background: #2B316F;
  color: #fff;
  text-align: right;
  margin: 0px 0px 0px 0px;
  height: 140px;
  padding: 0px 0px 0px 0px;
  top: auto;
  bottom: 0;
  a {
    color: #fff;
    text-decoration: none;
    text-align: right;
    font-size: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer2 {
  position: absolute;
  left: 0;
  right: 0;
}

.footer2 a {
  font-size: 18px;
}

.footer2 span {
  font-size: 13px;
}


.naclogo{
  display:block;
  position: absolute;
}
.naclogoimg{
  margin-bottom: 0px;

}
.footer-items {
  color: #000;
}

.footer__phone {
  margin-right: 40px;
  margin-top: 60px;
}
