// margin
.v-m30 {
  margin: 30px 0;
}
.mb20 {
  margin-bottom: 20px;
}

// padding

.p0-5 {
  padding: 0 5px;
}

.p15-20 {
  padding: 15px 20px;
}

// text
.ta-c {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

// size
.w150 {
  width: 150px;
}

