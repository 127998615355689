@-webkit-keyframes sector1 {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes sector1 {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

//-------------------------------------------------------------------------
//
// fade in
//
//-------------------------------------------------------------------------

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;

  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;

  }
}

//-------------------------------------------------------------------------
//
// pie
//
//-------------------------------------------------------------------------

//-------------------------------------------------------------------------
//
// slide in
//
//-------------------------------------------------------------------------

@-webkit-keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

//-------------------------------------------------------------------------
//
// slide out
//
//-------------------------------------------------------------------------

@-webkit-keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);

  }
  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);

  }
  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}

//-------------------------------------------------------------------------
//
// roll down
//
//-------------------------------------------------------------------------


@-webkit-keyframes rollDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes rollDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
//-------------------------------------------------------------------------
//
// roll up
//
//-------------------------------------------------------------------------


@-webkit-keyframes rollUp {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

@keyframes rollUp {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}


//-------------------------------------------------------------------------
//
// flip out X
//
//-------------------------------------------------------------------------

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}