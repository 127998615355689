.charts {

  &__row {
    text-align: justify;
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }
  &__item {
    box-shadow: $shadow;
    display: inline-block;
    height: 235px;
    width: 20.5%;
    vertical-align: top;
    margin-right: .5%;
    &_line {
      width: 100%;
      height: 175px;
    }
  }
  &__tabs {
    width: 37%;
    display: inline-block;
    height: 205px;
    vertical-align: top;
  }

  &__tabs-group {
    font-size: 0;
  }
  &__tabs-tab {
    display: inline-block;
    cursor: pointer;
    height: 30px;
    /*width: 55px;*/
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;
    vertical-align: top;
    border-radius: 3px 3px 0 0;
    background: lighten($c-back, 4);
    border-right: 1px solid $c-back;
    &:not(._tab-active) {
      &:hover {
        background: lighten($c-back, 2);
      }
    }
    &._tab-active {
      background: #ffffff;
    }

  }
  &__tabs-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid $c-main;
    overflow: hidden;
    position: relative;
    &_quarter {
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        background: $c-main;
        top: -10px;
        left: -10px;
      }
    }
    &_halve {
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        background: $c-main;
        top: -10px;
        left: 0;
      }
    }
    &_full {
      background: $c-main;
    }

  }
  &__tab-content {
    display: none;
    &._visible {
      display: block;
      animation: fadeIn 400ms;
      margin-bottom: 5px;
    }
  }
}

