.jvectormap-tip {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  box-shadow: $shadow;
  background: #fff;
  color: #000;
  font: 10px $fira-regular;
  padding: 4px 10px 2px;
}