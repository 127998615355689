.svg-2781-dims {
	width: 23.96px;
	height: 21.35px;
}

.svg-2834-dims {
	width: 14px;
	height: 24px;
}

.svg-2850-dims {
	width: 24px;
	height: 23.977px;
}

.svg-2905-dims {
	width: 24px;
	height: 23.67px;
}

.svg-2961-dims {
	width: 24.002px;
	height: 23.705px;
}

.svg-2964-dims {
	width: 24px;
	height: 23.52px;
}

.svg-apply-dims {
	width: 26px;
	height: 26px;
}

.svg-arrow-expand-dims {
	width: 23px;
	height: 6.026px;
}

.svg-camera-foto-dims {
	width: 24px;
	height: 24px;
}

.svg-cecutient-dims {
	width: 36.39px;
	height: 25.752px;
}

.svg-edit-cross-dims {
	width: 11.302px;
	height: 11.302px;
}

.svg-edit-eye-dims {
	width: 12.833px;
	height: 8.556px;
}

.svg-edit-pen-dims {
	width: 13.645px;
	height: 13.899px;
}

.svg-edit-trash-dims {
	width: 10.139px;
	height: 12.167px;
}

.svg-favorites-dims {
	width: 26.0005379px;
	height: 26px;
}

.svg-geolocation-dims {
	width: 24px;
	height: 24px;
}

.svg-organization-dims {
	width: 25.021px;
	height: 26.917px;
}

.svg-pie-electrons-dims {
	width: 30.003px;
	height: 29.413px;
}

.svg-pie-happy-dims {
	width: 24px;
	height: 24px;
}

.svg-pie-normal-dims {
	width: 24px;
	height: 24px;
}

.svg-pie-rouble-dims {
	width: 24px;
	height: 24px;
}

.svg-pie-sad-dims {
	width: 24px;
	height: 24px;
}

.svg-pie-shield-dims {
	width: 19.842px;
	height: 23.089px;
}

.svg-slide-left-dims {
	width: 7.7px;
	height: 13.3px;
}

.svg-slide-right-dims {
	width: 7.7px;
	height: 13.3px;
}

.svg-student-dims {
	width: 19.063px;
	height: 21.951px;
}

.svg-upload-list__remove-btn-dims {
	width: 15.052px;
	height: 15.052px;
}

